import React, { useMemo } from 'react';
import Image from 'next/image';

import classnames from 'src/classnames';
import { useTranslation, Trans } from 'src/i18n';
import { TP } from 'constants/index';
import Typography from 'components/uiLibrary/Typography';
import SEOComponent from 'components/NextSeo';
import AccountsTestimonials from 'components/Globals/Accounts/Registration/AccountsTestimonials';
import NextLink from 'components/NextLink';

import useDeviceTypeLayouts from 'utils/hooks/useDeviceTypeLayouts';
import usePageContext from 'utils/hooks/usePageContext';

import OperaLogo from 'public/images/logo.png';
import classes from './AuthentificationLayout.module.scss';

const AuthentificationLayout = ({ children, className }) => {
  const { t } = useTranslation();
  const { isMobile } = useDeviceTypeLayouts();
  const { navigate } = usePageContext();

  const seo = {
    title: t(`${TP}.SEO_TITLE_LOGIN_INDEX`),
    description: t(`${TP}.SEO_DESC_LOGIN_INDEX`),
    ogTitle: t(`${TP}.SEO_OG_TITLE_LOGIN_INDEX`),
    other: {
      noindex: true,
      nofollow: true,
    },
  };

  const homepageLinkProps = useMemo(() => navigate.getLinkProps({ onlyLinkProps: true }), [navigate]);

  return (
    <div className={classnames(classes.layout, className)}>
      <SEOComponent {...seo} />
      <div className={classes.login}>
        {!isMobile && (
          <div className={classes.header}>
            <div className={classes.leftSection}>
              <NextLink prefetch={false} {...homepageLinkProps} ariaLabel="Operabase Pro Home">
                <div className={classes.proLogoDesktop}>
                  <Image src={OperaLogo} alt="Operabase Home" height={14} width={140} />
                  <Typography size="12">{t(`${TP}.LP_SINCE`)}</Typography>
                </div>
              </NextLink>
            </div>
            <div className={classes.rightSection}>
              <Trans
                i18nKey={`${TP}.FN_CONVERSION_CASTING_BANNER_ITEMS`}
                ns="NS_APP_GLOBALS"
                components={{
                  ul: <ul className={classes.listWorks} />,
                  li: <li />,
                }}
              />
            </div>
          </div>
        )}
        {children}
      </div>
      <div className={classes.layout__right}>
        <AccountsTestimonials />
      </div>
    </div>
  );
};

export default AuthentificationLayout;
