import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Modal from 'components/uiLibrary/Modal';
import Testimonials from 'components/Globals/Testimonials';
import CustomerLogos from 'components/Globals/CustomerLogos';
import Login from 'components/Globals/Accounts/Login';
import Typography from 'components/uiLibrary/Typography';
import useQuickView from 'components/Globals/Layout/QuickView';

import { useDialogs } from 'utils/hooks/useDialogs';
import useDeviceTypeLayouts from 'utils/hooks/useDeviceTypeLayouts';
import { LOGIN_SOURCE } from 'constants/index';

import { useTranslation } from 'src/i18n';

import classes from './AccountsWrapper.module.scss';

const AccountsWrapper = ({ isModal }) => {
  const { quickViewType } = useQuickView();
  const { isMobile } = useDeviceTypeLayouts();
  const { setIsLoginDialog, isLoginDialog } = useDialogs();
  const { t } = useTranslation('NS_REGISTRATION_V4');
  const [isModalCloseDisabled, setIsModalCloseDisabled] = useState(false);

  const handleOnClose = () => {
    if (isLoginDialog.source !== LOGIN_SOURCE.RETURNING_USER && !quickViewType) {
      setIsLoginDialog({ isOpen: false });
    }
  };

  const content = (
    <Login
      step={isLoginDialog.step}
      onClose={handleOnClose}
      isModal={isModal}
      setIsModalCloseDisabled={setIsModalCloseDisabled}
    />
  );

  if (isModal) {
    return (
      <Modal
        isOpen={isLoginDialog}
        onClose={handleOnClose}
        className={isMobile ? classes.drawerContainer : ''}
        styles={{
          modalContainer: classes.modalContainer,
          closeIcon: classes.closeIcon,
          modalRoot: classes.modalRoot,
          drawerRoot: classes.drawerRoot,
          backdrop: classnames(classes.backdrop, {
            [classes.disabled]: isLoginDialog.source === LOGIN_SOURCE.RETURNING_USER,
          }),
        }}
        allowMobileDrawer
        disabledCloseHandler={isModalCloseDisabled}
      >
        <div className={classes.content}>{content}</div>
        <div className={classes.modalFooter}>
          <div className={classes.modalFooter__customerLogo}>
            <Typography size="14" italic color="secondary">
              {t(`FN_TRUSTED_BY_TITLE`)}
            </Typography>
            <CustomerLogos count="4" rowHeight="35" className={classes.trustedByStrip} allowShowAll />
            <div className={classes.testimonialContainer}>
              <Testimonials limit={3} hideTitle />
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  return content;
};

AccountsWrapper.propTypes = {
  isModal: PropTypes.bool.isRequired,
};

export default AccountsWrapper;
