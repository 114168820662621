import React from 'react';

import Testimonials from 'components/Globals/Testimonials';
import Typography from 'components/uiLibrary/Typography';
import CustomerLogos from 'components/Globals/CustomerLogos';

import { TP } from 'constants/consts';
import { useTranslation } from 'src/i18n';

import classes from './AccountsTestimonials.module.scss';

const AccountsTestimonials = () => {
  const { t } = useTranslation('NS_REGISTRATION_V4');
  return (
    <div className={classes.testimonials}>
      <div className={classes.testimonials__trustedSection}>
        <Typography className={classes.testimonials__title} weight="medium" variant="h1" size="16" secondaryFont>
          {t(`${TP}.FN_LOGIN_POPUP_BOTTOM_SECTION_TITLE`)}
        </Typography>
        <div className={classes.testimonials__trustedSection_grid}>
          <CustomerLogos
            rows={3}
            className={classes.testimonials__trustedSection_grid_logos}
            rowHeight={70}
            count={9}
            allowShowAll={false}
            gap={4}
          />
        </div>
      </div>
      <div className={classes.testimonials__testimonialsSection}>
        <Typography className={classes.testimonials__title} weight="medium" variant="h1" size="16" secondaryFont>
          {t(`${TP}.FN_TESTIMONIALS_SECTION_TITLE`)}
        </Typography>
        <div className={classes.testimonialsContainer}>
          <Testimonials limit={3} hideTitle />
        </div>
      </div>
    </div>
  );
};

export default AccountsTestimonials;
